import React, { useEffect } from 'react'
import { Script } from 'gatsby'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const TwilioChat = () => {
    const theme = useTheme()
    const downMd = useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        const checkTwilio = () => {
            if (window.Twilio) {
                window.Twilio.initWebchat({
                    serverUrl: process.env.GATSBY_MODE === 'production' ?
                        "https://webchat-react-app-serverless-6231-prod.twil.io"
                        :
                        "https://webchat-react-app-serverless-8896-prod.twil.io",
                    lob: "envios",
                    theme: {
                        isLight: true
                    }
                })
            }
        }

        if (window.Twilio) {
            checkTwilio()
        } else {
            window.addEventListener('TwilioScriptLoaded', checkTwilio)
        }

        return () => {
            window.removeEventListener('TwilioScriptLoaded', checkTwilio)
        }
    }, [])

    return (
        <>
            <Script
                src={process.env.GATSBY_MODE === 'production' ?
                    "https://webchat-react-app-serverless-6231-prod.twil.io/static/js/main.js"
                    :
                    "https://webchat-react-app-serverless-8896-prod.twil.io/static/js/main.js"}
                onLoad={() => {
                    const event = new Event('TwilioScriptLoaded')
                    window.dispatchEvent(event)
                }}
            />
            <div id="twilio-webchat-widget-root" style={{ display: downMd ? "none" : "inherit" }}></div>
        </>
    )
}

export default TwilioChat